import * as React from 'react';
import { Row, Col, Table, Tag } from 'antd';
import Select from '../../components-v2/Select';
import { CUSTOM_FORMAT, DATE_FORMAT } from '../../utils/constants';
import { dataActions } from '../../redux/store';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { DataTypeField } from '../../containers/TSDefineCodes';
import { CloseOutlined } from '@ant-design/icons';

const { Option } = Select;
interface DateDefineCodeMappingProps {
  mappingType: string;
  dataTypeFormat: string;
  mappingId: string;
  fieldsData: DataTypeField[];
  config: any;
}
interface DefineCodeMappingProps {
  fieldName: string;
  fieldMapping: FieldMapProps[];
  fieldId: string;
}

interface FieldMapProps {
  description: string;
  code: string | number;
}

export const TestScoreCodeMapping = (props: DefineCodeMappingProps): React.ReactElement => {
  const config = useSelector((state: RootStateOrAny) => state.modifiedConfig);
  const updatedConfig = JSON.parse(JSON.stringify(config));
  const dispatch = useDispatch();

  const tagColor = '#f5f5f5';
  const tagTextColor = '#141497';
  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  const changeDefineCodeMapping = (key: string, val: (string | number)[]) => {
    const mapping = {};
    let updatedMapping = updatedConfig.translateConfig.valueMappings[props.fieldId];
    const valueMaps = [...updatedMapping];
    if (val) {
      mapping[key] = val;
      if (val.length == 1) {
        updatedMapping.push({
          toValue: key,
          fromValue: val[0],
        });
      }
    }
    for (const valueMap of valueMaps) {
      if (key === valueMap.toValue) {
        if (mapping[key].includes(valueMap.fromValue)) {
          mapping[key].forEach((newCode) => {
            if (newCode != valueMap.fromValue) {
              updatedMapping.push({
                toValue: key,
                fromValue: mapping[key].pop(),
              });
            }
          });
        } else {
          const index = updatedMapping.indexOf(valueMap);
          updatedMapping.splice(index, 1);
        }
      } else {
        const fromValue = mapping[key].pop();
        if (fromValue) {
          updatedMapping.push({
            toValue: key,
            fromValue: fromValue,
          });
        }
      }
    }
    updatedMapping = updatedMapping.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.toValue === value.toValue && t.fromValue === value.fromValue),
    );
    updatedConfig.translateConfig.valueMappings[props.fieldId] = updatedMapping;
    dispatch(dataActions.addModifiedConfig(updatedConfig));
  };

  const defineCodeTableColumns = [
    {
      title: `Naviance Field: ${props.fieldName}`,
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: `File Code: ${props.fieldId}`,
      width: '60%',
      render: (data) => (
        <Select
          mode="tags"
          size="large"
          tagRender={tagRender}
          defaultValue={data.code}
          style={{ width: '100%' }}
          options={[]}
          tokenSeparators={[',']}
          dropdownStyle={{ display: 'none' }}
          placeholder="Add custom codes"
          onChange={(change) => {
            changeDefineCodeMapping(data.description, change);
          }}
        />
      ),
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 className="infoHeading uploadHeading">{props.fieldName}</h2>
      </Col>
      <Col span={16}>
        <Table
          rowKey={(obj) => obj.description}
          columns={defineCodeTableColumns}
          dataSource={props.fieldMapping}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export const TestScoreDateMapping = (props: DateDefineCodeMappingProps): React.ReactElement => {
  const config = useSelector((state: RootStateOrAny) => state.modifiedConfig);
  const dateFields = JSON.parse(JSON.stringify(useSelector((state: RootStateOrAny) => state.dateFields)));
  const dispatch = useDispatch();
  const updatedConfig = JSON.parse(JSON.stringify(config));

  const changeDateFormat = (value: string) => {
    updatedConfig.validateConfig.columns[props.mappingId].validTypes = ['datetime'];
    updatedConfig.validateConfig.columns[props.mappingId].dateTimeFormat = [value];
    dateFields[props.mappingId] = value;
    dispatch(dataActions.addDateFields(dateFields));
    dispatch(dataActions.addModifiedConfig(updatedConfig));
  };

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 className="infoHeading uploadHeading">{props.mappingType}</h2>
      </Col>
      <Col span={16}>
        <Select
          defaultValue={
            config.validateConfig.columns[props.mappingId].dateTimeFormat
              ? config.validateConfig.columns[props.mappingId].dateTimeFormat[0]
              : 'MM/DD/YYYY'
          }
          placeholder="Choose a Date Format"
          onChange={changeDateFormat}
          className="select select-text"
          style={{ width: 500 }}
          disabled={props.dataTypeFormat === CUSTOM_FORMAT ? false : true}
        >
          {DATE_FORMAT.map((item) => {
            return (
              <Option key={item} value={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      </Col>
    </Row>
  );
};
