import './app.scss';

import React, { ReactElement, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Shell from './containers/Shell';
import { IntegrationContext } from './utils/context';
import ScrollToTop from './utils/scrollToTop';

let featureFlags: Record<string, unknown>,
  setFeatureFlags: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;

export const App = (): ReactElement => {
  [featureFlags, setFeatureFlags] = useState({});
  let isCleverIntegration = false;
  let dataImportPath = '/data-import-new';

  return (
    <Router basename="/data-import-new">
      <ScrollToTop />
      <IntegrationContext.Provider value={{ isCleverIntegration, dataImportPath }}>
        <Shell />
      </IntegrationContext.Provider>
    </Router>
  );
};

export { featureFlags };
export default App;
