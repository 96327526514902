import { Col, Divider, Row, Spin } from 'antd';
import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { API_URL, ApiClientType } from '../../utils/constants';
import MainTemplate from '../Shell/MainTemplate';
import { apiClient } from '../../utils/apiClient';
import LeftAngleIcon from '../../../assets/Icon/LeftAngleIcon';
import { dataActions } from '../../redux/store';
// component specific styling
import './styles.scss';
import { ErrorAlert } from '../../components-v2/ErrorAlert';

const PSDataImportJobSummary: React.FC = () => {
  const history = useHistory();
  const { jobGuid } = useParams<{ jobGuid: string }>();
  const dispatch = useDispatch();
  const [jobMeta, setJobMeta] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [grouping, setGrouping] = React.useState({ groupingId: '', groupingName: '' });
  const [summary, setSummary] = React.useState<any>();

  const fetchJob = async () => {
    try {
      const { data } = await apiClient(
        { url: `/testscore/jobs/${jobGuid}`, method: 'get' },
        ApiClientType.DATA_INGEST_TOOL,
      );
      setSummary(data);
      setGrouping({ groupingId: data.groupingId, groupingName: data.groupingName });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowError(true);
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
    }
  };

  React.useEffect(() => {
    setLoading(true);
    void fetchJob();
  }, []);

  if (loading) {
    return (
      <MainTemplate title="">
        <Row justify="center">
          <Col span={12}>
            <div className="spinner">
              <Spin size="large" />
            </div>
          </Col>
        </Row>
      </MainTemplate>
    );
  }

  const downloadFile = async (event) => {
    // Prevent default behavior when clicking a link
    event.preventDefault();

    const res = await apiClient(
      { url: `/testscore/jobs/${jobGuid}/download/logs`, method: 'get' },
      ApiClientType.DATA_INGEST_TOOL,
    );

    const file = new Blob([res.data], { type: res.headers['Content-Type'] });
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'log.csv');

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  const { groupingId, groupingName } = grouping;

  const summarySection = (
    <>
      <h1 className="import-summary-details">Test Import Summary</h1>
      <Row>
        <Col span={14}>
          <div className="review-import-details">
            <div>
              <strong>File: </strong>
              {summary?.fileName ? summary.fileName : ''}
            </div>
            <div>
              <strong>Data Type: </strong>
              {summary?.dataType}
            </div>
            <div>
              <strong>Field Matching: </strong>
              {summary?.matchedFieldsCount} Matched, {summary?.unmatchedFieldsCount} Unmatched
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
  const detailSection = (
    <>
      <Col span={14}>
        <Divider style={{ marginTop: '35px', marginBottom: '35px' }} />
      </Col>
      <h1 className="import-summary-details">Test Import Details</h1>
      <Row>
        <Col span={14}>
          <div className="review-import-details">
            <div>
              <strong>Import Status: </strong> {summary?.status}
            </div>
            {/* <div style={{ margin: '5px 5px 5px 5px' }}>
            <strong>{summary.noOfRows} Total Records </strong>
          </div> */}
            {/* <ul>
            <li>
              <strong> {summary.rowsWithErrors} Rows with Errors: </strong> Records Skipped
            </li>
            <ul>
              <li>
                <strong>{summary.validationResults?.errors['Invalid Types']?.count} Invalid types: </strong>
                {summary.validationResults?.errors['Invalid Types']?.columns.map(
                  (item, index) => (index ? ', ' : '') + item,
                )}
              </li>
              <li>
                <strong> {summary.validationResults?.errors['Blank Values']?.count} Blank Values</strong>{' '}
                {summary.validationResults?.errors['Blank Values']?.columns.map(
                  (item, index) => (index ? ', ' : '') + item,
                )}
              </li>
            </ul>
          </ul>
          <ul>
            <li>
              <strong> {summary.rowsWithWarnings} Rows with Warnings: </strong> Records Not Skipped
            </li>
            <ul>
              <li>
                <strong>{summary.validationResults?.warnings['Invalid Types']?.count} Invalid types: </strong>
                {summary.validationResults?.warnings['Invalid Types']?.columns.map(
                  (item, index) => (index ? ', ' : '') + item,
                )}
              </li>
              <li>
                <strong> {summary.validationResults?.warnings['Invalid Values']?.count} Blank Values</strong>{' '}
                {summary.validationResults?.warnings['Invalid Values']?.columns.map(
                  (item, index) => (index ? ', ' : '') + item,
                )}
              </li>
            </ul>
          </ul> */}
            <a
              download
              href={`${API_URL}/data-import-new/testscore/jobs/${jobGuid}/download/logs`}
              onClick={downloadFile}
              className="export-log-details"
            >
              Export Log for additional details
            </a>
          </div>
        </Col>
        <Col span={14}>
          <Divider style={{ marginTop: '35px', marginBottom: '35px' }} />
        </Col>
      </Row>
    </>
  );

  return (
    <MainTemplate
      title="View Log"
      titleTestId="data_job_summary_title"
      classes="mainLogsSection mainConfigSection import-log-summary"
    >
      <>
        <Row justify="space-between" align="middle">
          <Col span={4}>
            <div style={{ float: 'left', width: '30px' }}>
              <LeftAngleIcon />
            </div>
            <div>
              <a className="back-button-summary" onClick={() => history.goBack()}>
                Back
              </a>
            </div>
          </Col>
          <Col span={10}>
            {summary && grouping && (
              <div style={{ float: 'right' }}>
                <Link
                  to={{ pathname: `/test-score-import`, state: { groupingId, groupingName } }}
                  style={{ marginLeft: '10px' }}
                  className="button button-blue"
                  onClick={() => {
                    dispatch(dataActions.addGroupingId(groupingId));
                    dispatch(dataActions.addGroupingName(groupingName));
                  }}
                >
                  Reupload
                </Link>
              </div>
            )}
          </Col>
          <Col span={10}></Col>
        </Row>
        {showError ? (
          <ErrorAlert />
        ) : (
          <>
            {summarySection}
            {detailSection}
          </>
        )}
      </>
    </MainTemplate>
  );
};

export default PSDataImportJobSummary;
