import { Button, Col, Dropdown, Menu, Modal, Row, Space, Table, TablePaginationConfig, Divider } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import CheckmarkCircleIcon from '../../../assets/Icon/CheckmarkCircleIcon';
import GearIcon from '../../../assets/Icon/GearIcon';
import MoreVerticalIcon from '../../../assets/Icon/MoreVerticalICon';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { apiClient } from '../../utils/apiClient';
import MainTemplate from '../Shell/MainTemplate';
import { IntegrationContext } from '../../utils/context';
import { ApiClientType } from '../../utils/constants';

const DataImport: React.FC = () => {
  const history = useHistory();
  const [importRecords, setImportRecords] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const integrationContext = React.useContext(IntegrationContext);

  const methodColumn = {
    title: 'Method',
    dataIndex: 'method',
    key: 'method',
    render: (method) => <span data-test-id="method">{method}</span>,
  };

  const DataImportTableColumns = [
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      width: '15%',
      render: (dataType) => <span data-test-id="data_type">{dataType}</span>,
    },
    {
      title: 'Start Time',
      dataIndex: 'start',
      key: 'start',
      width: '14%',
      render: (date) => <span data-test-id="start_date">{new Date(date).toLocaleString()}</span>,
    },
    {
      title: 'End Time',
      dataIndex: 'end',
      key: 'end',
      width: '14%',
      render: (date) => <span data-test-id="end_date">{date ? new Date(date).toLocaleString() : ''}</span>,
    },
    {
      title: 'User/Source',
      key: 'author',
      render: (row) => (
        <span data-test-id="data_type">
          {row.author && row.author != '' ? row.author : row.source != '-' ? row.source : 'Testscore'}
        </span>
      ),
    },
    {
      title: 'Method',
      render: (row) => <span data-test-id="data_type">{row.source === '-' ? 'Manual' : row.source}</span>,
    },
    {
      title: '# of Rows',
      dataIndex: 'noOfRows',
      key: 'noOfRows',
      render: (noOfRows) => <span data-test-id="num_of_rows">{noOfRows === -1 ? '-' : noOfRows}</span>,
    },
    {
      title: 'Status',
      width: '20%',
      render: (row) => (
        <span data-test-id="status" className="status">
          {getIconAndStatus(row)}
        </span>
      ),
    },
    {
      title: 'Alerts',
      width: '10%',
      render: (row) => {
        return (
          <span data-test-id="alerts">
            {row.done ? (
              <Button
                type="link"
                onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
              >
                {row.noOfAlerts === -1 ? '-' : row.noOfAlerts}
              </Button>
            ) : (
              <span style={{ padding: '4px 15px' }}>{row.noOfAlerts === -1 ? '-' : row.noOfAlerts}</span>
            )}
          </span>
        );
      },
    },
    {
      title: 'More',
      render: (row) => (
        <Dropdown
          overlay={userMenu(
            row.done,
            row.importDataGuid,
            row.undoEnabled,
            row.canContinueImport,
            row.navianceStatus,
            row.dataType,
          )}
        >
          <a href="#" onClick={(e) => e.preventDefault()} data-test-id={`${row.importDataGuid}-more_btn`}>
            <MoreVerticalIcon />
          </a>
        </Dropdown>
      ),
    },
  ];

  const userMenu = (
    done: boolean,
    guid: string,
    undoEnabled: boolean,
    canContinueImport: boolean,
    status = '',
    dataType: string,
  ) => (
    <Menu>
      {/* {canContinueImport && (status === 'IMPORT_COMPLETED' || status === 'IMPORT_COMPLETED_WITH_ALERTS') && (
        <Menu.Item
          key={`${guid}-continue_import`}
          data-test-id="menu_continue"
          onClick={() => reStartImport(guid, dataType)}
        >
          Start Import
        </Menu.Item>
      )} */}
      <Menu.Item
        key={`${guid}-log`}
        data-test-id={`${guid}-menu_log}`}
        disabled={!done}
        onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${guid}`)}
      >
        View log
      </Menu.Item>
      {/* {undoEnabled ? (
        <Menu.Item key={`${guid}-undo`} data-test-id="menu_undo" onClick={() => setUndoModal(guid)}>
          Undo
        </Menu.Item>
      ) : (
        <></>
      )} */}
    </Menu>
  );

  const getIconAndStatus = (row) => {
    if (row.undo && row.undo.status) {
      switch (row.undo.status) {
        case 'COMPLETE':
          return (
            // <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            // <Button type="link" onClick={() => history.push(`/jobs`)}>
            <Button
              type="link"
              onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
            >
              //TODO
              <CheckmarkCircleIcon /> Undo Complete
            </Button>
          );
        case 'FAILED':
          return (
            // <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            // <Button type="link" onClick={() => history.push(`/jobs`)}>
            <Button
              type="link"
              onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
            >
              //TODO add data import new
              <AlertErrorIcon /> Undo Failed
            </Button>
          );
        default:
          return <>Undo in progress</>;
      }
    }
    switch (row.navianceStatus) {
      case 'VALIDATION_IN_PROGRESS':
        return <>{row.isTestImport ? 'Test ' : ''}Import Validation in progress</>;
      case 'SYNC_VALIDATION_IN_PROGRESS':
        return <>{row.isTestImport ? 'Test ' : ''}Sync Validation in Progress</>;
      case 'IMPORT_IN_PROGRESS':
        return <>{row.isTestImport ? 'Test ' : ''}Import in progress</>;
      case 'SYNC_IN_PROGRESS':
        return <>{row.isTestImport ? 'Test ' : ''}Sync in progress</>;
      case 'IMPORT_COMPLETED':
        return (
          <Button
            type="link"
            onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
          >
            <CheckmarkCircleIcon /> {row.isTestImport ? 'Test ' : ''}Import Complete
          </Button>
        );
      case 'IMPORT_COMPLETED_WITH_ALERTS':
        return (
          <Button
            type="link"
            onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
          >
            <AlertYellowIcon /> {row.isTestImport ? 'Test ' : ''}Import Completed with Alerts
          </Button>
        );
      case 'SYNC_COMPLETED_WITH_ALERTS':
        return (
          <Button
            type="link"
            onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
          >
            <AlertYellowIcon /> {row.isTestImport ? 'Test ' : ''}Sync Completed with Alerts
          </Button>
        );
      case 'CRITICAL_ERROR':
        return (
          <Button
            type="link"
            onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
          >
            <AlertErrorIcon /> Critical Error - Import Cancelled
          </Button>
        );
      // TODO: case 'FILE_REMOVED': return <>File Removed via Undo</>;
      case 'TESTING_COMPLETED':
        return (
          <Button
            type="link"
            onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
          >
            <CheckmarkCircleIcon /> Testing Completed
          </Button>
        );
      case 'TESTING_COMPLETED_WITH_ALERTS':
        return (
          <Button
            type="link"
            onClick={() => history.push(`${integrationContext.dataImportPath}/jobs/${row.importDataGuid}`)}
          >
            <AlertYellowIcon /> Testing Completed with Alerts
          </Button>
        );
      default:
        return <>{row.navianceStatus}</>;
    }
  };

  const pagination = {
    total: totalRecords,
    showSizeChanger: false,
    onChange: async (page) => await fetchJobs(page),
  } as TablePaginationConfig;

  const fetchJobs = async (page = 1) => {
    try {
      setLoading(true);
      const { data } = await apiClient({ url: `/jobs?page=${page}`, method: 'get' }, ApiClientType.DATA_INGEST_TOOL);
      setImportRecords(data?.jobs || []);
      setTotalRecords(data?.meta.totalJobs || 0);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Jobs', 'Failure in getting data from server.');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    // if (!integrationContext.isCleverIntegration) {
    //   fetchPluginVersion(true);
    // }
    void fetchJobs();
  }, []);

  const configureBtn = (
    <Space>
      <button
        className="button"
        onClick={() => history.push(integrationContext.dataImportPath + '/configure')}
        data-test-id="configuration_btn"
      >
        Configure <GearIcon />
      </button>
    </Space>
  );

  if (integrationContext.isCleverIntegration) {
    DataImportTableColumns.splice(4, 0, methodColumn);
  }

  return (
    <>
      <MainTemplate
        title="Data Import History"
        titleTestId="data_import_title"
        showDivider={true}
        // subheaderRSContent={configureBtn}
      >
        <Row>
          <Col span={24}>
            <div className="mainDataImportTable sisImportTable">
              <Table
                rowKey="id"
                loading={loading}
                columns={DataImportTableColumns}
                dataSource={importRecords}
                pagination={pagination}
              ></Table>
            </div>
          </Col>
        </Row>
      </MainTemplate>
    </>
  );
};

export default DataImport;
