const css = `@charset "UTF-8";
.custom-bullet {
  list-style: none;
  margin-bottom: 0;
}

.custom-bullet li::before {
  content: "•";
  color: #fcd703;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.imported-courses-list {
  margin: 0;
  list-style: none;
}

.back-button-summary {
  color: #141497;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  text-decoration: underline;
}

.import-log-summary .titleField {
  font-family: Open Sans;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333 !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvcmVobWFuL0Rlc2t0b3AvQ29kZS9kYXRhLWluZ2VzdC10b29sL2FwcHMvZGF0YS10ZXN0c2NvcmUtdWkvc3JjL2FwcC9jb250YWluZXJzL1BTRGF0YUltcG9ydEpvYlN1bW1hcnkiLCJzb3VyY2VzIjpbInN0eWxlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFBQTtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
