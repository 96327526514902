import { ComponentClass, FunctionComponent } from 'react';
import DataImportTestScore from '../../../../data-testscore-ui/src/app/containers/TestScoreImport';
import TestScoreImport from '../containers/TSImport/TSImport';
import DataImport from '../containers/PSDataImport';
import PSDataImportJobSummary from '../containers/PSDataImportJobSummary';

export interface Routes {
  path: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  page: ComponentClass | FunctionComponent<object>;
  logo?: React.FunctionComponent;
  props?: Record<string, unknown>;
  breadcrumbs?: string[];
}

const routes = (integrationContext) => {
  const { dataImportPath, isCleverIntegration } = integrationContext;
  const routes: { [key: string]: Routes } = {
    '/': {
      path: '/',
      page: DataImportTestScore,
      name: 'Data Import New',
    },
    '/test-score-import': {
      path: '/data-import-new/test-score-import',
      page: TestScoreImport,
      name: 'Test Score Import',
    },
    '/import-history': {
      path: '/',
      page: DataImport,
      name: 'Data Import New',
    },
    [dataImportPath + '/jobs/:jobGuid']: {
      path: '/jobs/:jobGuid',
      page: PSDataImportJobSummary,
      name: 'View Log',
    },
  };
  return routes;
};

export default routes;
export const defaultRoute = routes['/data-import-new'];
