import React from 'react';
import Icon from '@ant-design/icons';

export const ToolTip = () => (
  <svg width="24" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM7.76246 11.5C7.40808 11.5 7.10908 11.6198 6.86545 11.8595C6.62181 12.0992 6.5 12.3946 6.5 12.7458C6.5 13.1416 6.62597 13.4496 6.87791 13.6697C7.12985 13.8899 7.42469 14 7.76246 14C8.08915 14 8.37708 13.8885 8.62625 13.6656C8.87542 13.4426 9 13.136 9 12.7458C9 12.3946 8.88095 12.0992 8.64286 11.8595C8.40476 11.6198 8.1113 11.5 7.76246 11.5ZM5.912 2.48399C6.536 2.16133 7.264 2 8.096 2C8.86934 2 9.552 2.13486 10.144 2.40458C10.736 2.6743 11.1933 3.04107 11.516 3.50489C11.8387 3.96872 12 4.47286 12 5.01735C12 5.44588 11.908 5.82147 11.724 6.14413C11.54 6.46679 11.3213 6.74533 11.068 6.97976C11.0427 7.0032 11.0153 7.02825 10.986 7.05489L10.537 7.45146L9.704 8.16326C9.52267 8.31954 9.37733 8.45692 9.268 8.5754C9.15867 8.69388 9.07733 8.80227 9.024 8.90058C8.97067 8.99889 8.92933 9.0972 8.9 9.19551L8.85776 9.34917L8.73817 9.83914C8.61228 10.2797 8.30489 10.5 7.816 10.5C7.53867 10.5 7.30533 10.4143 7.116 10.2429C6.92667 10.0715 6.832 9.81688 6.832 9.47909C6.832 9.0556 6.90133 8.68884 7.04 8.37878C7.17867 8.06873 7.36267 7.79649 7.592 7.56206C7.66844 7.48391 7.75378 7.40087 7.848 7.31292L8.15733 7.03438C8.26933 6.93663 8.39022 6.83398 8.52 6.72642L8.66049 6.60967L9.01241 6.31034L9.26 6.08741C9.412 5.94373 9.54 5.78366 9.644 5.60721C9.748 5.43075 9.8 5.23918 9.8 5.03247C9.8 4.62915 9.64133 4.28885 9.324 4.01157C9.00666 3.73428 8.59734 3.59564 8.096 3.59564C7.50933 3.59564 7.07733 3.73554 6.8 4.01535C6.52267 4.29515 6.288 4.70729 6.096 5.25178C5.91467 5.82147 5.57067 6.10632 5.064 6.10632C4.76533 6.10632 4.51333 6.00675 4.308 5.80761C4.10267 5.60847 4 5.39294 4 5.16103C4 4.68209 4.16266 4.19684 4.488 3.70529C4.81333 3.21374 5.288 2.80664 5.912 2.48399Z"
      fill="#677274"
    />
  </svg>
);

const ToolTipIcon = ({ inactive = false, editSubjectArea = false, ...props }) => {
  return <Icon component={ToolTip} {...props} />;
};

export default ToolTipIcon;
