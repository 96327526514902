import * as React from 'react';
import MainTemplate from '../../../../../data-testscore-ui/src/app/containers/Shell/MainTemplate';
import { apiClient } from '../../utils/apiClient';
import Loading from '../../components/Loading';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { dataActions } from '../../redux/store';
import './TestScoreImport.css';
import { Button } from 'antd';
import { ApiClientType } from '../../utils/constants';
import NewTabIcon from '../../../assets/Icon/NewTab';

type groupingType = {
  id: string;
  name: string;
  status: string;
  CB: string;
  MB: string;
  PB: string;
  PT: string;
  CT: string;
  MD: string;
};

const DataImportTestScore: React.FC = () => {
  const history = useHistory();
  const [groupingData, setGroupingData] = React.useState<groupingType[]>([]);
  const [loading, setloading] = React.useState(true);
  const dispatch = useDispatch();

  const fetchGroupingData = async () => {
    try {
      const { data } = await apiClient({ url: '/grouping', method: 'get' }, ApiClientType.TEST_SCORE);
      setGroupingData(data);
      setloading(false);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting grouping Data', 'Failure in getting data from server.');
    }
  };

  React.useEffect(() => {
    dispatch(dataActions.resetReduxStates());
    fetchGroupingData();
  }, []);

  return (
    <>
      <MainTemplate title="Data Import " superScript="New" showDivider={true}>
        <>
          <div className="data-import-section">
            <p className="test-score-heading">Data Import History</p>
            <Button className="btn-view-history" type="link" onClick={() => history.push(`/import-history`)}>
              View Data Import History
            </Button>
          </div>
          <div className="data-import-section">
            <p className="test-score-heading">Start Data Import</p>

            {loading && <Loading title="Fetching Import Types" />}

            <div>
              {groupingData.map((item, index) => {
                return item.status === 'Published' ? (
                  <div className="data-import-item" key={index}>
                    <Link
                      to={{ pathname: `/test-score-import`, state: { groupingId: item.id, groupingName: item.name } }}
                      className="data-import-link"
                      onClick={() => {
                        dispatch(dataActions.addGroupingId(item.id));
                        dispatch(dataActions.addGroupingName(item.name));
                      }}
                    >
                      {item.name}
                    </Link>
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="data-import-section">
            <p className="test-score-heading">Data Import Templates</p>
            <a
              href="https://help.naviance.com/Content/DigitalDownloads/Templates_New.htm"
              className="data-import-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              View and download data import templates
            </a>
            <p className="templates-new-tab-text">
              <NewTabIcon />
              <span>This link opens in a new tab</span>
            </p>
          </div>
        </>
      </MainTemplate>
      <MainTemplate
        title=""
        classes="new-data-import mainMatchFieldsSection mainConfigSection importKeySection"
        showDivider={false}
      >
        <div>
          <p className="test-score-heading">Data Import Key</p>
          <p className="data-import-key">2094ab5173bf64df4f3bcf40bcaf5b84</p>
        </div>
      </MainTemplate>
    </>
  );
};

export default DataImportTestScore;
