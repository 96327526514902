import axios, { AxiosRequestConfig } from 'axios';
import { API_URL, IS_LOCAL_ENV, ApiClientType } from './constants';

const getApiUrl = async () => {
  let devLocalPrefix = '';
  if (IS_LOCAL_ENV) {
    // use full local url to avoid relative/non-dev issues
    devLocalPrefix = `https://${location.hostname}${location.port ? `:${location.port}` : ':8865'}`;
  }
  const { data: config } = await axios.get(`${devLocalPrefix}/config.json`);
  return `${config.apiUrl}`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiClient = async (configs: AxiosRequestConfig, clientType: ApiClientType) => {
  // use the embedding app's window context global for the API_URL if it exists
  const baseUrl = API_URL ? API_URL : await getApiUrl();
  const useAxios = axios.create({
    baseURL: baseUrl + clientType,
    withCredentials: true,
    headers: {
      'disable-rewrite': '1',
      'header-meta': '1',
    },
  });
  return await useAxios.request(configs);
};
