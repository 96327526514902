import { environment } from '../../environments/environment';

declare let window: Window;

interface Window {
  DATA_IMPORT_NEW_UI_CONTEXT: {
    HIGHSCHOOL_ID: string;
    API_URL: string;
    toggles: {
      testImportToggleIsOn: boolean;
    };
    props: {
      state: string;
    };
  };
}
const { DATA_IMPORT_NEW_UI_CONTEXT } = window;
export const IS_LOCAL_ENV = location.hostname === 'data-import-new.local.naviance.com';
export const API_URL = DATA_IMPORT_NEW_UI_CONTEXT?.API_URL;
export enum ApiClientType {
  TEST_SCORE = '/testscore-admin',
  DATA_INGEST_TOOL = '/data-import-new',
  SUCCEED_API = '',
}
export const IS_TEST_IMPORT_TOGGLE_ON = DATA_IMPORT_NEW_UI_CONTEXT?.toggles?.testImportToggleIsOn;
export const STATE_CODE = DATA_IMPORT_NEW_UI_CONTEXT?.props?.state;
export const HIGHSCHOOL_ID = DATA_IMPORT_NEW_UI_CONTEXT?.HIGHSCHOOL_ID;
export const PSError = 'PSError';
export const TOS_ENDPOINT = '/district/tos.php';
export const PRIVACY_ENDPOINT =
  'https://ui-production.papaya.naviance.com/naviance-static-assets/html/policies/privacy.html';
export const TOU_ENDPOINT = 'https://ui-production.papaya.naviance.com/naviance-static-assets/html/policies/tou.html';

export const OFFICIAL_FORMAT = 'Official';
export const CUSTOM_FORMAT = 'Custom';
export const PUBLISHED_STATUS = 'Published';
export const TEST_STATUS = 'Test';
export const ALL_STATES_CODE = 'ALL_CLIENTS';

export const DATE_FORMAT = [
  'MM/DD/YYYY',
  'MMDDYYYY',
  'MM/DD/YY',
  'MMDDYY',
  'MM/YYYY',
  'MM-YYYY',
  'MMYY',
  'YYYYMMDD',
  'YYMMDD',
  'YYYY/MM',
  'YYYY-MM',
  'YYMM',
];

export const uniqueIdentifierGroup = {
  keyGroupA: ['first_name', 'last_name', 'birthdate'],
  keyGroupB: ['student_id', 'last_name'],
  keyGroupC: ['state_student_id', 'last_name'],
};
export const listOfUniqueIdentifiers = ['first_name', 'last_name', 'birthdate', 'state_student_id', 'student_id'];
